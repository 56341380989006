import logo from './logo.svg';
import './App.css';
import {useEffect, useState} from "react";
import APIService from "./services/APIService";
import WriteFilter from "./components/WriteFilter";
import ReadFilter from "./components/ReadFilter";
import {ethers} from "ethers";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

    const [provider, setProvider] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);

    const [web3Token, setWeb3Token] = useState(null);
    const [isMetaPassOwner, setIsMetaPassOwner] = useState(false);
    const [watchers, setWatchers] = useState([]);

    if (!provider && window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(provider);
    }

    useEffect(() => {
        const establishAddress = async () => {
            if (window.ethereum && window.ethereum.selectedAddress) {
                setSelectedAddress(window.ethereum.selectedAddress);
                setIsMetaPassOwner(await APIService.check(window.ethereum.selectedAddress));
            }
        }

        if (!selectedAddress) {
            establishAddress();
        }

    },[selectedAddress]);

    const connect = async () => {
        const addresses = await provider.send("eth_requestAccounts", []);
        setSelectedAddress(addresses[0]);
        setIsMetaPassOwner(await APIService.check(addresses[0]));
    };

    const signLoginMessage = async () => {
        const nonce = Math.floor(Math.random() * 999999999999);
        const issuedAt = new Date();
        const expirationTime = new Date(issuedAt.getTime() + 60 * 60 * 24 * 1000);

        const body = `FLIPPER wants you to sign in with your Ethereum account.\n\nWeb3 Token Version: 2\nNonce: ${nonce}\nIssued At: ${issuedAt.toISOString()}\nExpiration Time: ${expirationTime.toISOString()}`;

        const signature = await provider.getSigner().signMessage(body);

        APIService.setWeb3Token(btoa(JSON.stringify({
            signature,
            body,
            issuedAt: issuedAt.toISOString(),
            nonce,
            expirationTime: expirationTime.toISOString(),
        })));

        setWeb3Token(APIService.getWeb3Token());
    }

    if (!provider) {
        return <div className="grid place-items-center h-screen">
            <div className="container">
                <div className="flex-col">
                    <h4>Please install MetaMask</h4>
                </div>
            </div>
        </div>
    }

    const fetchWatchers = async () => {
        const dbWatchers = await APIService.getAllWatchers();
        setWatchers([...dbWatchers]);
    };

    return (
      <div className="grid place-items-center h-screen">
          <div className="container">
              <div className="flex-col">
                  {!selectedAddress &&
                      <div className="flex-row text-center items-center">
                          <div className="flex-col">
                              <button
                                  onClick={() => connect()}
                                  className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-1 px-2 rounded">
                                  Connect wallet
                              </button>
                          </div>
                      </div>}

                  {!isMetaPassOwner && selectedAddress &&
                  <div className="flex-row text-center items-center">
                      <div className="flex-col">
                        <h4>Loading permission by address..</h4>
                      </div>
                  </div>
                  }

                  {isMetaPassOwner && selectedAddress && !web3Token &&
                      <div className="flex-row text-center items-center">
                          <div className="flex-col">
                          <button
                              onClick={() => signLoginMessage()}
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">
                                  Sign login to FLIPPER
                              </button>
                          </div>
                      </div>
                  }

                  {isMetaPassOwner && selectedAddress && web3Token &&
                      <>
                          <ReadFilter
                            watchers={watchers}
                            fetchWatchers={fetchWatchers}
                          />
                          <WriteFilter
                              fetchWatchers={fetchWatchers}
                          />
                      </>
                  }

                  <ToastContainer />
              </div>
          </div>
      </div>
  );
}

export default App;
