import {useEffect, useState} from "react";
import APIService from "../services/APIService";
import {PlayIcon, PauseIcon} from "@heroicons/react/outline";
import {ethers} from "ethers";
import {TrashIcon} from "@heroicons/react/solid";
import Spinner from "./Spinner";
import {toast} from "react-toastify";

function ReadFilter({watchers, fetchWatchers}) {
    const [loadingWatchers, setLoadingWatchers] = useState({});
    const [editWatchers, setEditWatchers] = useState({});

    useEffect(() => {
        fetchWatchers();
    }, []);

    const toggleWatcher = async (index) => {
        const newRunning = !watchers[index].running;
        setLoadingWatchers({
            ...loadingWatchers,
            [index]: true,
        });

        const newWatcher = await APIService.updateWatcher(watchers[index].id, {
            running: newRunning,
        });

        await fetchWatchers();

        setLoadingWatchers({
            ...loadingWatchers,
            [index]: false,
        });

        toast(`Watcher "${watchers[index].additionalName}" updated`);
    };

    const saveEditWatchers = async(index) =>{
        if(!editWatchers.hasOwnProperty(index)){
            toast(`Watcher "${watchers[index].additionalName}" HAS NOT BEEN MODIFIED! ERROR!`);
            return;
        }

        const newWatcher = editWatchers[index];
        await APIService.updateWatcher(newWatcher.id,{
            absoluteMaxGas:newWatcher.absoluteMaxGas,
            targetPriceETH:newWatcher.targetPrice
        })

        await fetchWatchers();
        toast(`Watcher "${watchers[index].additionalName}" updated`);
    }

    const deleteWatcher = async (index) => {
        setLoadingWatchers({
            ...loadingWatchers,
            [index]: true,
        });

        const oldName = watchers[index].additionalName;

        await APIService.deleteWatcher(watchers[index].id);
        await fetchWatchers();

        setLoadingWatchers({
            ...loadingWatchers,
            [index]: false,
        });

        toast(`Watcher "${oldName}" deleted`);
    };

    const updateWatcherMultiplier = async (index, newMultiplier) => {
        setLoadingWatchers({
            ...loadingWatchers,
            [index]: true,
        });

        const newWatcher = await APIService.updateWatcher(watchers[index].id, {
            gasStrategyMult: newMultiplier,
        });

        fetchWatchers();

        setLoadingWatchers({
            ...loadingWatchers,
            [index]: false,
        });

        toast(`Watcher "${watchers[index].additionalName}" updated`);
    };

    return <div className="flex-row border-4 rounded text-center items-center">
        <div className="flex-col">

        <table className="my-0 mx-auto divide-y divide-gray-400">
            <thead>
            <tr className="divide-x divide-x-gray-400 p-1">
                <th className="p-2">Name</th>
                <th className="p-2">Tokens #</th>
                <th className="p-2">Hits ✅</th>
                <th className="p-2">Hits 🚫</th>
                <th className="p-2">Hits ❓</th>
                <th className="p-2">Target Price</th>
                <th className="p-2">Max Cost (Fee+Price)</th>
                <th className="p-2">Active?</th>
                <th className="p-2">Delete</th>
                <th className="p-2">Actions</th>
                <th className="p-2">Loading</th>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
            {watchers.map((x, watcherIndex) => (<tr key={`watcher_item_${x.id}`} className="divide-x divide-x-gray-200">
                <td className="p-2">{x.additionalName}</td>
                <td className="p-2">{x._count.watcherTokens}</td>
                <td className="p-2">{x.hits.filter(y => y.status === 1).length}/{x.maxSuccessHits}</td>
                <td className="p-2">{x.hits.filter(y => y.status === 2).length}/{x.maxFailedHits}</td>
                <td className="p-2">{x.hits.filter(y => y.status === 0).length}</td>
                <td className="p-2">                    
                    <input type="number" 
                        step={0.001} 
                        value={
                            
                            editWatchers.hasOwnProperty(watcherIndex)
                            ?
                            ethers.utils.formatEther(editWatchers[watcherIndex].targetPrice).toString()
                            :
                            ethers.utils.formatEther(x.targetPrice).toString()
                        }
                        onChange={(e)=>{
                            let oldWatcher = x;
                            if(editWatchers.hasOwnProperty(watcherIndex)){
                                oldWatcher=editWatchers[watcherIndex];
                            }
                            const newWatcher={
                                ...oldWatcher,
                                targetPrice:ethers.utils.parseEther(e.target.value)
                            }
                            setEditWatchers({
                                ...editWatchers,
                                [watcherIndex]:newWatcher,
                            });
                        }}
                    />
                    E
                </td>
                <td className="p-2">                    
                    <input type="number" 
                            step={0.001} 
                            value={
                                editWatchers.hasOwnProperty(watcherIndex)
                                ?
                                ethers.utils.formatEther(editWatchers[watcherIndex].absoluteMaxGas).toString()
                                :
                                ethers.utils.formatEther(x.absoluteMaxGas).toString()
                            }
                            onChange={(e)=>{
                                let oldWatcher = x;
                                if(editWatchers.hasOwnProperty(watcherIndex)){
                                    oldWatcher=editWatchers[watcherIndex];
                                }
                                const newWatcher={
                                    ...oldWatcher,
                                    absoluteMaxGas:ethers.utils.parseEther(e.target.value)
                                }
                                setEditWatchers({
                                    ...editWatchers,
                                    [watcherIndex]:newWatcher,
                                });
                            }}
                        />
                    E
                </td>
                <td className="p-2">{x.running ?
                    <PauseIcon
                        className="h-8 w-8 text-red-500 mx-auto my-0"
                        onClick={(() => toggleWatcher(watcherIndex))}
                    /> :
                    <PlayIcon
                        className="h-8 w-8 text-green-500 mx-auto my-0"
                        onClick={(() => toggleWatcher(watcherIndex))}
                    />}
                </td>
                <td>
                    <TrashIcon
                        onClick={(() => deleteWatcher(watcherIndex))}
                        className="h-8 w-8 text-black mx-auto my-0"
                    />
                </td>
                <td className="p-2">
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded" 
                        onClick={()=>saveEditWatchers(watcherIndex)}>
                        Save
                    </button>
                </td>
                {loadingWatchers[watcherIndex] && <td>
                    <Spinner />
                </td>}
            </tr>))}
            </tbody>
        </table>
        </div>
    </div>;
}

export default ReadFilter;