import {useState} from "react";
import APIService from "../services/APIService";

function WriteFilter({ fetchWatchers }) {

    const defaultFilter = {
        filterName: '',
        collectionAddress: '',
        collectionSlug: '',
        tokenIDs: '',
        gasStrategyMult: 3,
        absoluteMaxGas: 0.05,                
        targetPriceETH: 0.25,
        expirationTimestamp: 1902930450,
        maxSuccessHits: 5,
        maxFailedHits: 25,
    };

    const [newFilter, setNewFilter] = useState(defaultFilter);

    const saveNewFilter = async () => {
        const watcher = await APIService.createWatcher(newFilter);
        setNewFilter(defaultFilter);

        fetchWatchers();
    };

    return (
        <div className="flex-row border-4 rounded text-center items-center">
            <div className="flex-col">
                <p className="text-xl">Add collection token ids</p>
                <form className="mt-4">
                    <div className="flex-row">
                        <div className="flex-col">
                            <p>
                                Filter name
                            </p>
                            <input
                                id="filter-name"
                                value={newFilter.filterName}
                                onChange={event => {
                                    setNewFilter({
                                        ...newFilter,
                                        filterName: event.target.value,
                                    })
                                }}
                                type="text"
                                placeholder="Bored Ape Fake MCDonalds Party"
                                className="border-2 border-black"
                            />
                        </div>
                    </div>

                    <div className="flex-row">
                        <div className="flex-col">
                            <p>
                                OpenSea Slug
                            </p>
                            <input
                                id="collection-slug"
                                value={newFilter.collectionSlug}
                                onChange={event => {
                                    setNewFilter({
                                        ...newFilter,
                                        collectionSlug: event.target.value,
                                    })
                                }}
                                type="text"
                                placeholder="accualy iz opensea slug"
                                className="border-2 border-black"
                            />
                        </div>
                    </div>
                    <div className="flex-row">
                        <div className="flex-col">
                            <p>
                                Collection address
                            </p>
                            <input
                                id="collection-address"
                                value={newFilter.collectionAddress}
                                onChange={event => {
                                    setNewFilter({
                                        ...newFilter,
                                        collectionAddress: event.target.value,
                                    })
                                }}
                                type="text"
                                placeholder="real address here"
                                className="border-2 border-black"
                            />
                        </div>
                    </div>

                    <div className="flex-row">
                        <div className="flex-col">
                            <p>
                                Token IDs
                            </p>
                            <textarea
                                id="token-ids"
                                value={newFilter.tokenIDs}
                                onChange={event => {
                                    setNewFilter({
                                        ...newFilter,
                                        tokenIDs: event.target.value,
                                    })
                                }}
                                className="border-2 border-black"
                                rows={10}
                            />
                        </div>
                    </div>
                    
                    <div className="flex-row">
                        <div className="flex-col">
                            <p>
                                Target price (ETH)
                            </p>
                            <input
                                id="target-price"
                                value={newFilter.targetPriceETH}
                                onChange={event => {
                                    setNewFilter({
                                        ...newFilter,
                                        targetPriceETH: event.target.value,
                                    })
                                }}
                                type="number"
                                step={0.001}
                                className="border-2 border-black"
                            />
                        </div>
                    </div>

                    <div className="flex-row">
                        <div className="flex-col">
                            <p>
                            Max Cost: Fee+Price (ETH) 🐇
                            </p>
                            <input
                                id="absolute-max-gas"
                                value={newFilter.absoluteMaxGas}
                                onChange={event => {
                                    setNewFilter({
                                        ...newFilter,
                                        absoluteMaxGas: parseFloat(event.target.value),
                                    })
                                }}
                                type="number"
                                step={0.001}
                                className="border-2 border-black"
                            />
                        </div>
                    </div>


                    <div className="flex-row">
                        <div className="flex-col">
                            <p>
                                Auto-deletion (UTC timestamp w/o millis)|2030 default
                            </p>
                            <input
                                id="expire-at"
                                value={newFilter.expirationTimestamp}
                                onChange={event => {
                                    setNewFilter({
                                        ...newFilter,
                                        expirationTimestamp: event.target.value,
                                    })
                                }}
                                type="number"
                                className="border-2 border-black"
                            />
                        </div>
                    </div>

                    <div className="flex-row">
                        <div className="flex-col">
                            <p>
                                Max success hits ✅
                            </p>
                            <input
                                id="max-success-hits"
                                value={newFilter.maxSuccessHits}
                                onChange={event => {
                                    setNewFilter({
                                        ...newFilter,
                                        maxSuccessHits: event.target.value,
                                    })
                                }}
                                type="number"
                                className="border-2 border-black"
                            />
                        </div>
                    </div>

                    <div className="flex-row">
                        <div className="flex-col">
                            <p>
                                Max failed hits 🚫
                            </p>
                            <input
                                id="max-failed-hits"
                                value={newFilter.maxFailedHits}
                                onChange={event => {
                                    setNewFilter({
                                        ...newFilter,
                                        maxFailedHits: event.target.value,
                                    })
                                }}
                                type="number"
                                className="border-2 border-black"
                            />
                        </div>
                    </div>

                    <button
                        type="submit"
                        onClick={event => {
                            event.preventDefault();

                            saveNewFilter();
                        }}
                        className="bg-gray-500 hover:bg-gray-900 text-white py-1 px-3 rounded m-2"
                    >
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default WriteFilter;
