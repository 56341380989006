import axios from "axios";
import { ethers } from "ethers";

const baseURI = 'https://flipper-api.mbntech.ro';
//const baseURI = 'http://localhost:5000';

let web3Token = null;

const APIService = {
    setWeb3Token: token => web3Token = token,

    getWeb3Token: () => web3Token,

    createWatcher: async (params) => {
        const result = await axios.post(`${baseURI}/watchers`, {
            slug: params.collectionSlug,
            address: params.collectionAddress.toLowerCase(),
            additionalName: params.filterName,
            maxSuccessHits: params.maxSuccessHits,
            maxFailedHits: params.maxFailedHits,
            expireAt: parseInt(params.expirationTimestamp),
            targetPrice: ethers.utils.parseEther(params.targetPriceETH.toString()).toString(),
            absoluteMaxGas:ethers.utils.parseEther(params.absoluteMaxGas.toString()).toString(),
            watcherTokens: params.tokenIDs.split(',')
                .map(x => x.trim())
                .map(x => parseInt(x))
                .filter(x => !isNaN(x)),
        }, {
            headers: {
                'Web3-Token': web3Token,
            }
        });

        return result.data;
    },

    getAllWatchers: async () => {
        const result = await axios.get(`${baseURI}/watchers`, {
            headers: {
                'Web3-Token': web3Token,
            }
        });

        return result.data;
    },

    deleteWatcher: async (id) => {
        const result = await axios.delete(`${baseURI}/watchers/${id}`, {
            headers: {
                'Web3-Token': web3Token,
            }
        });

        return result.data;
    },

    updateWatcher: async (id, changes) => {
        const result = await axios.put(`${baseURI}/watchers/${id}`, {
            absoluteMaxGas: changes.absoluteMaxGas.toString(),
            targetPrice: changes.targetPriceETH.toString(),
            running: changes.running,
        }, {
            headers: {
                'Web3-Token': web3Token,
            }
        });

        return result.data;
    },

    check: async (address) => {
        const result = await axios.get(`${baseURI}/check/${address}`);

        return result.data.whitelisted;
    },
};

export default APIService;
